<template>
  <div class="instant-win-settings">
    <!--  Header    -->
    <div class="p-0 m-0 d-flex w-100 align-items-center justify-content-between">
      <CCol col="2" class="d-flex p-0">
        <h3 class="mb-3 mr-1 bread-content">{{ 'Edit Settings' }}</h3>
        <IconWithTooltip class="zq--header-tooltip" :text="descriptions.settings.titleDescription"/>
      </CCol>
      <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
      <CButton
        class="action-create-button zq--responsive-button__common"
        type="submit"
        @click="updateSettings"

      >
        Update Settings
      </CButton>
    </div>
      <ZqInstantWinType
        :label="getLabel('instantWinType')"
        v-model="localInstantWinType"
        :tooltip="getTooltipByName('instantWinType')"
        :isDisabled="true"
      />
      <div class="d-flex align-items-center w-100 name-tag-wrapper">
        <CIInputCol
          class="col-5"
          :label="getLabel('id')"
          :tooltip="getTooltipByName('id')"
          v-model="id"
          :placeholder="'id'"
          :required="true"
          :name="'id'"
          :disabled="true"
        />
      </div>
      <div class="d-flex align-items-center w-100 name-tag-wrapper">
        <CIInputCol
          class="col-5"
          :label="getLabel('name')"
          :tooltip="getTooltipByName('name')"
          v-model="name"
          :placeholder="'name'"
          :required="true"
          :name="'name'"
        />
        <CITagField
          class="col-5 pl-0 instant-win-tag"
          :is-column="true"
          v-model="tags"
          :type="model"
          :tooltip="getTooltipByName('tags')"
        />
      </div>
      <div class="d-flex align-items-center w-100 text-area-fields-wrapper">
        <CITextArea
          class="col-5"
          :is-column="true"
          :label="getLabel('description')"
          :tooltip="getTooltipByName('description')"
          v-model="description"
          :placeholder="'description'"
          :name="description"
          :isToolbar="true"
        />
        <CITextArea
          class="col-5"
          :is-column="true"
          :label="getLabel('termsAndConditions')"
          :tooltip="getTooltipByName('termsAndConditions')"
          v-model="termsAndConditions"
          :placeholder="'description'"
          :name="termsAndConditions"
          :isToolbar="true"
        />
      </div>
    <div v-if="isTipicoSpace" class="d-flex align-items-center w-100">
      <div class="d-flex flex-column col-5 pl-0 ">
        <label class="col-form-label font-italic w-50">{{ texts.createPage.settings.startDate }}</label>
        <div class="d-flex align-items-center">
          <DatePicker
            :fieldName="'startDate'"
            :dateProp="localStartDate"
            @input="setStartDate"
            :isValid="starDataValidate !== false"
            :placeholder="texts.createPage.settings.startDate"
            class="mr-2 mb-3 w-100 p-0"
            :disabledDate="disabledStartDate"
            required
          />
          <IconWithTooltip :text="descriptions.availableFrom"/>
        </div>
      </div>
      <div class="d-flex flex-column col-5 pl-0 ">
        <label class="col-form-label font-italic w-50">{{ texts.createPage.settings.endDate }}</label>
        <div class="d-flex align-items-center">
          <DatePicker
            :fieldName="'endDate'"
            :dateProp="localEndDate"
            @input="setEndDate"
            :isValid="endDataValidate !== false"
            :placeholder="texts.createPage.settings.endDate"
            class="mr-2 mb-3 w-100 p-0"
            :disabledDate="disabledStartDate"
            required
            :class="{'zq--select--error': starDataValidate === false}"
          />
          <IconWithTooltip :text="descriptions.availableTo"/>
        </div>
      </div>
    </div>
      <div class="d-flex align-items-center">
        <CIMetaDataField
          v-model="metadata"
          class="w-100"
          :label="getLabel('metadata')"
          :tooltip="getTooltipByName('metadata')"
          :placeholder="'placeholder'"
          :colSm="'5'"
          :colMd="'5'"
          :colLg="'5'"
          :colXl="'5'"
          :paddingRight="'pr-4'"
        />
      </div>
  </div>
</template>

<script>
import CITagField from '@/shared/components/CITagField.vue';
import CITextArea from '@/shared/components/formComponents/CITextArea.vue';
import ZqInstantWinType from '@/shared/components/ZqInstantWinType.vue';
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import CIMetaDataField from '@/shared/components/CIMetaDataField.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import { startCase } from 'lodash';
import { mapActions } from 'vuex';
import DatePicker from '@/shared/components/DatePicker';

export default {
  name: 'EditInstantWinSettings',
  components: { IconWithTooltip, CIMetaDataField, CIInputCol, ZqInstantWinType, CITextArea, CITagField, DatePicker },
  props: {
    list: [Array],
    tilesData: [Array],
    descriptions: Object,
    texts: Object,
    instantWinType: [Number, String]
  },
  data() {
    return {
      formList: null,
      obj: null,
      requiredText: 'Required',
      model: 'instantWin',
      id: '',
      localInstantWinType: 1,
      name: '',
      description: '',
      termsAndConditions: '',
      metadata: null,
      customFields: null,
      tags: [],
      isReady: false,
      isSettingsDataSaved: true,
      startDate: '',
      localStartDate: '',
      endDate: '',
      localEndDate: '',
      starDataValidate: null,
      endDataValidate: null,
    }
  },
  created() {
    this.initialize();
    this.setStartDate(this.localStartDate);
    this.setEndDate(this.localEndDate);
  },
  computed: {
    isTipicoSpace() {
      // const allowedSpaces = [];
      const allowedSpaces = ['tipico5g', 'tipico5g-staging'];
      const currentSpace = localStorage.getItem('zq-space-name');

      return allowedSpaces.includes(currentSpace);
    },
  },
  watch: {
    localInstantWinType(val) {
      const item = this.formList.find(item => item.key === 'instantWinType');
      item.value = val;
    },
    name(val) {
      const item = this.formList.find(item => item.key === 'name');
      item.value = val;
    },
    description(val) {
      const item = this.formList.find(item => item.key === 'description');
      item.value = val;
    },
    termsAndConditions(val) {
      const item = this.formList.find(item => item.key === 'termsAndConditions');
      item.value = val;
    },
    metadata(val) {
      const item = this.formList.find(item => item.key === 'metadata');
      item.value = val;
    },
    tags(val) {
      const item = this.formList.find(item => item.key === 'tags');
      item.value = val;
    },
  },
  methods: {
    ...mapActions('instantWins', ['handleGetInstantWins_item', 'handleUpdateInstantWins']),
    initialize() {
      this.formList = this.list;

      this.id = this.getValueByName('id');
      this.localInstantWinType = this.getValueByName('instantWinType');
      this.name = this.getValueByName('name');
      this.description = this.getValueByName('description');
      this.termsAndConditions = this.getValueByName('termsAndConditions');
      this.metadata = this.getValueByName('metadata');
      this.tags = this.getValueByName('tags');
      this.customFields = this.getValueByName('customFields');
      this.localStartDate = this.getValueByName('startDate');
      this.localEndDate = this.getValueByName('endDate');
      this.isReady = true;
    },
    getLabel(fieldName) {
      const foundObject = this.list.find(item => item.key === fieldName);

      return foundObject?.required ? startCase(foundObject.label) + '*' : startCase(foundObject.label);
    },
    getValueByName(name) {
      return this.formList.find(item => item.key === name)?.value;
    },
    getTooltipByName(name) {
      return this.descriptions.settings[name]
    },
    updateSettings(isOneStepEdit = false) {
      const data = {
        id: this.id,
        constraints: this.getValueByName('constraints'),
        name: this.name,
        description: this.description,
        instantWinType: this.localInstantWinType,
        termsAndConditions: this.termsAndConditions,
        tiles: [...this.tilesData],
        metadata: this.metadata,
        customFields: this.customFields,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      data.tiles.forEach(tile => {
        if (tile.reward) {
          delete tile.reward.rewardType;
          delete tile.reward.id;
          delete tile.reward.spaceName;
          delete tile.reward.created;
          delete tile.reward.entityType;
          delete tile.reward.entityId;
          delete tile.reward.translatableFields;
          delete tile.reward.constraints;
          delete tile.reward.order;
        }
        delete tile.autoProbability;
      });

      const body = [data];

      this.handleUpdateInstantWins({updateInstantWinRequestArray: body})
        .then(data => {
          this.isSettingsDataSaved = true;
          if (data.length && isOneStepEdit) {
            // setTimeout(() => {
            //   this.$router.push({
            //     name: 'PreviewInstantWin',
            //     params: {
            //       id: data[0].id,
            //     }
            //   })
            // }, 1500)
          }
        })
    },
    setStartDate(date) {
      if (date) {
        this.starDataValidate = true;
        const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

        this.startDate = date ? isoDate : "";
        this.localStartDate = date;
      }

    },
    setEndDate(date) {
      this.endDataValidate = false;

      if (date) {
        const isoDate = date
          ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
          : "";

        this.endDate = isoDate;
        this.localEndDate = date;
      }
    },
    disabledStartDate(d) {
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
  }
};
</script>

<style scoped lang="scss">

</style>
